<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) The following compound possesses two chiral centres labelled "A" and "B". What is the
        stereochemical configuration (R or S) of these two chiral centres?
      </p>

      <p class="mb-2 pl-12">
        <v-img
          src="/img/assignments/laurierSandboxStructureQuestion4.png"
          alt=""
          max-width="239px"
        />
      </p>

      <v-radio-group v-model="inputs.multipleChoiceAnswer" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'LaurierSandboxQ4',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: 'A: R; B: R', value: 'R,R'},
        {text: 'A: R; B: S', value: 'R,S'},
        {text: 'A: S; B: R', value: 'S,R'},
        {text: 'A: S; B: S', value: 'S,S'},
      ],
    };
  },
};
</script>
